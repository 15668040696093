<template>
  <div class="my-addon">
    <div class="panel-page">
      <div class="panel-heading">
        <h2>我购买的插件</h2>
      </div>

      <div class="alert alert-danger-light">
        <b>温馨提示:</b><br>
        1.在你使用插件前请仔细阅读<a>《插件授权协议》</a>。<br>
        <b>2.付费插件禁止分享、转售或复制给他人使用，如有发现保留追究法律责任的权利。</b><br>
        3.标准授权仅支持自营项目使用，禁止用于外包，高级授权支持自营项目、外包项目使用。<br>
        4.标准授权在有效期内可补差价升级到高级授权版本。
      </div>

      <div v-if="tableData.length === 0"
           class="text-center">暂无记录，<a>前往插件市场浏览</a></div>
      <div v-else>
        <el-table :data="tableData"
                  border
                  style="width: 100%">
          <el-table-column prop="pluginName"
                           label="插件名称"
                           width="180">
          </el-table-column>
          <el-table-column prop="description"
                           label="插件介绍"
                           width="180">
          </el-table-column>
          <!-- <el-table-column prop="pluginCreater"
                           label="作者">
          </el-table-column> -->

          <el-table-column label="标准版售价">
            <template v-slot="scope">
              {{scope.row.pluginPrice / 100}} 元
            </template>
          </el-table-column>

          <el-table-column label="高级版售价">
            <template v-slot="scope">
              {{scope.row.pluginPrice2 / 100}} 元
            </template>
          </el-table-column>

          <el-table-column label="已购版本">
            <template v-slot="scope">
              <el-tag :type="scope.row.memberPlugins[0].priceLevel===1?'success':'danger'">{{scope.row.memberPlugins[0].priceLevel===1?'基础版':'高级版'}}</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="购买日期">
            <template v-slot="scope">
              {{scope.row.memberPlugins[0].createdAt|dateFormat}}
            </template>
          </el-table-column>

          <el-table-column label="有效期限">
            <template v-slot="scope">
              {{getDataEnd(scope.row.memberPlugins[0])}}
            </template>
          </el-table-column>

          <el-table-column prop="downloadTimes"
                           label="下载次数">
          </el-table-column>
          <el-table-column fixed="right"
                           label="操作"
                           width="100">
            <template v-slot="scope">
              <el-popover
                placement="bottom"
                trigger="click">
                <ul class="dropdown-menu">
                  <li v-for="(item,index) in scope.row.pluginInfo" :key="'version_'+index">
                    <el-button size="mini" type="text" @click="handleInstall(item.url)">版本: {{item.infoVersion}}</el-button>
                  </li>
                </ul>
                <el-button slot="reference" size="mini" type="text" icon="el-icon-arrow-down">下载</el-button>
              </el-popover>
              <el-button v-if="showUpdate(scope.row.memberPlugins[0])" slot="reference" size="mini"
                         type="text" icon="el-icon-top" @click="updateAdvanced(scope.row)">升级高级版</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadPlugin } from '@/api/plugin'
import { getMyPlugIn } from '@/api/myAddon.js'
import { dateFormat, fenToYuan, isBefore } from '@/utils/common'
import buy from '@/views/components/buy/Index'
import _ from 'lodash'
export default {
  name: 'myAddon',
  data () {
    return {
      tableData: []
    }
  },
  methods: {
    handleInstall (url) {
      downloadPlugin(this.BaseUrl + '/' + url + '&token=' + encodeURIComponent(this.$store.state.user.token))
    },
    getDataEnd (info) {
      if (info.priceLevel !== 1) {
        return '无限制'
      }
      const date = new Date(info.createdAt)
      date.setDate(date.getDate() + 365)
      return dateFormat(date)
    },
    showUpdate (info) {
      if (info.priceLevel === 2) {
        return false
      }
      const date = new Date(info.createdAt)
      const dateStr = date.setDate(date.getDate() + 365)
      return isBefore(dateFormat(dateStr))
    },
    updateAdvanced (plugin) {
      const props = {
        standardTitle: plugin.pluginName,
        standardPrice: fenToYuan(plugin.pluginPrice),
        advancedTitle: plugin.pluginName,
        advancedPrice: fenToYuan(plugin.pluginPrice2 - plugin.pluginPrice),
        disabledBasics: true,
        activeTabIndex: 'advanced',
        isUpdate: 1
      }
      const buyDialog = this.$dialog({ title: '立即支付', width: '650px', 'close-on-click-modal': false, 'show-close': false }, false).content(h =>
        h(buy, {
          props: { pluginId: _.toNumber(plugin.pluginId), ...props },
          on: {
            // 轮询的结果为支付成功
            paySuccess: () => {
              buyDialog.close()
            },
            // 用户自行确认已付款
            confirmPay: ({ type, status }) => {
              // console.log(type, status)
              if (type === 'wechatPay' && status === 'SUCCESS') {
                console.log('使用微信支付成功')
              }
              if (type === 'aliPay' && status === 'TRADE_SUCCESS') {
                console.log('使用阿里支付成功')
              }
              buyDialog.close()
            },
            exit: () => {
              buyDialog.close()
            }
          }
        })
      )
    }
  },
  created () {
    getMyPlugIn().then(res => {
      const { code, data } = res
      if (code === 0 && Array.isArray(data)) {
        this.tableData = data
      }
    })
  }
}
</script>

<style lang="less" scoped>
.alert-danger-light,
.alert-error-light {
  background-color: #f2dede;
  border-color: #ebcdcd;
  color: #b94a48;
}

.alert {
  border-radius: 3px;
}
.alert {
  padding: 15px;
  margin-bottom: 17px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.text-center {
  text-align: center;
}

.dropdown-menu ,dropdown-menu li{
  padding:0;margin:0;list-style:none
}
.dropdown-menu li{
  text-align: center;
}
.dropdown-menu li:hover{
  background: rgba(225,229,255,0.75);
}
.install-alert{
  margin-bottom: 20px;
}
</style>
